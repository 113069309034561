import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
const layoutProps = {};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Lotus`}</strong>{` is a street drug that became popular in `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`, known for its psychedelic and hallucinegenic effects. It became extremely popular with students at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`, due to its potency with `}<a parentName="p" {...{
        "href": "/Acronist",
        "title": "Acronist"
      }}>{`Acronists`}</a>{`. `}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      